import React, { useEffect, useState } from 'react';
import ContactForm from '../components/ContactForm';
import config from '../utils/config';
import Footer from '../components/Footer';
import Review from '../components/Review';
import Guarantee from '../components/Guarantee';

function Landing() {

	const [expiration, setExpiration] = useState<Date>()

	useEffect(() => {
		const now = new Date()
		//now.setDate(now.getDate() + 1)
		setExpiration(now)
	}, [])

	return (
		<div className="flex flex-1 flex-col h-full">
			{/* Top navigation bar */}
			<div className="flex-1 flex-col">
				<div className="flex items-center flex-col lg:flex-row py-2 px-20 bg-slate-700">
					<div className="flex flex-1">
						<img className="h-12 w-52" src="../assets/logo.png" />{/* <!-- TODO: --> */}
					</div>
					<a className="cta-base mt-2 lg:mt-0 hidden lg:inline" href="#shop">{config.cta_label.toUpperCase()}</a>
				</div>

				{/* <!-- Urgency --> */}
				<div
					className="flex justify-center text-white bg-red-600 py-1 font-bold sticky top-0 z-10">
					L'offerta scade OGGI: {expiration?.toLocaleString("it-IT").split(", ")[0]}</div>
				{/* <!-- Headline section --> */}
				<div className="flex flex-col items-center">
					<div className="text-center flex flex-col">
						<div className="px-10 mt-8 text-4xl font-bold ">{config.headline}</div>
						<span className="font-bold text-green-600 mt-8">Ordina ora e paghi alla consegna.</span>
						<span className="font-bold text-green-600">Nessun anticipo!</span>
						{config.subheadline}
					</div>
					<img className="h-[300px] w-[300px] lg:h-[700px] lg:w-[700px] mt-8" src="../assets/power-image.png" />{/* <!-- TODO: --> */}
				</div>

				<div className="flex flex-col items-center mt-6 divide-y-2">
					<div className="flex flex-col lg:flex-row self-stretch max-lg:divide-y-2 lg:divide-x-2">
						{/* <!-- Bullet point --> */}
						<div className="flex flex-1 flex-shrink-0 justify-center pb-6 px-10">
							<div className="flex flex-col space-y-6 justify-center">
								{
									config.bullet_points.map(bp => (
										<div className="flex items-center">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
												className="icon-size text-green-600">
												<path fill-rule="evenodd"
													d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
													clip-rule="evenodd" />
											</svg>
											{bp}
										</div>
									))
								}
							</div>
						</div>


					</div>

					{/* <div className="flex flex-col items-center self-stretch py-6 px-10 text-center">
						<span className="text-3xl font-bold">{config.product_name.toUpperCase()} è ideale per</span>
						<div className="flex flex-col lg:flex-row items-center self-stretch mt-6 space-y-6 lg:space-y-0">
							{
								config.usages.map(u => (
									<div className="flex flex-1 flex-col items-center space-y-6 lg:space-y-4">
										{u.image}
										{u.text}
									</div>
								))
							}
						</div>
					</div> */}

					<div className="flex flex-col items-center self-stretch py-10 px-10 text-center">
						<span className="text-3xl font-bold">{config.product_name.toUpperCase()} è il miglior alleato di ogni uomo</span>
						<span className="text-lg mt-10">{config.product_name} è uno spray con una potente concentrazione di <span className='font-bold'>feromoni</span>, cioè delle particelle che stanno alla base dell'<span className='font-bold'>attrazione</span>. Questo spray svela il vero segreto della seduzione. I feromoni, regolatori invisibili dell'attrazione umana, una volta diffusi nell'aria dal tuo corpo, ti renderanno <span className='font-bold'>irresistibile</span> agli altri. </span>
						<span className='text-lg mt-4'>La seduzione è il risultato di una <span className='font-bold'>chimica</span> innata. I legami si formano dall'interno, grazie a complessi meccanismi chimici. Ciò che dà il via a questi meccanismi magici sono i feromoni.</span>
						{/* <span className='mt-4'>Gli ingredienti che hanno dato vita a {config.product_name} sono: Alchol denaturato, olii vitalizzanti, acqua, profumi.</span> */}
					</div>

					<div className="flex flex-col items-center self-stretch py-6 px-10 text-center">
						<span className="text-3xl font-bold">Come funziona {config.product_name.toUpperCase()}</span>
						<div className="flex flex-col items-center self-stretch mt-6">
							{/* {config.instructions.map(i => (
								<div className="flex flex-1 flex-col items-center space-y-6 lg:space-y-4">
									{i.image}
									{i.text}
								</div>
							))} */}
							<span>{config.product_name} è <span className='font-bold'>semplicissimo</span> da usare, si utilizza come un normale profumo, applicandolo sul <span className='font-bold'>collo, petto e polsi</span>. È consigliato non strofinare in modo da mantenere "viva" più a lungo la fragranza e prolungare il rilascio di feromoni.</span>
							<span className='mt-4'>{config.product_name} però è più di un semplice profumo, puoi addirittura spruzzarne un po' in casa per creare un'atmosfera più <span className='font-bold'>sensuale ed intrigante</span>.</span>
							<img className='mt-6' src='../assets/instruction.png'></img>
						</div>
					</div>

					<div className='py-6 px-10 self-stretch flex flex-col items-center text-center'>
						<span className='text-3xl font-bold'>Cosa dicono di {config.product_name}</span>
						<div className='mt-8 self-stretch space-y-4 items-start text-start -mx-4'>
							{config.reviews.map(r => (
								<Review review={r}/>
							))}
						</div>
					</div>

					<div id="shop" className="flex flex-col items-center self-stretch py-10 px-10 text-center bg-slate-400">
						<span className="text-5xl font-bold text-yellow-300 underline">OFFERTA LIMITATA</span>
						<span className="text-2xl font-bold mt-10">{config.product_name}</span>
						<span className="text-2xl font-bold text-yellow-300 mt-4">OGGI A METÀ PREZZO</span>
						<div className="flex flex-col items-center justify-center mt-4">
							<div className="font-bold text-red-600 text-3xl line-through">{config.full_price}€</div>
							<div className="text-6xl font-bold text-yellow-300">{config.discounted_price}€</div>
							<Guarantee textColorClass='text-yellow-300'/>
						</div>

						<div className="flex items-center mt-6">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon-size">
								<path d="M12 7.5a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5z" />
								<path fill-rule="evenodd"
									d="M1.5 4.875C1.5 3.839 2.34 3 3.375 3h17.25c1.035 0 1.875.84 1.875 1.875v9.75c0 1.036-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 011.5 14.625v-9.75zM8.25 9.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM18.75 9a.75.75 0 00-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 00.75-.75V9.75a.75.75 0 00-.75-.75h-.008zM4.5 9.75A.75.75 0 015.25 9h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V9.75z"
									clip-rule="evenodd" />
								<path
									d="M2.25 18a.75.75 0 000 1.5c5.4 0 10.63.722 15.6 2.075 1.19.324 2.4-.558 2.4-1.82V18.75a.75.75 0 00-.75-.75H2.25z" />
							</svg>
							<span className="ml-2 offer-bullet-point-text">Pagamento alla consegna</span>
						</div>

						<div className="flex justify-center items-center mt-2">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
								className="icon-size text-red-600">
								<path
									d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
							</svg>
							<span className="ml-2 offer-bullet-point-text text-red-600">SOLO 7 PEZZI RIMASTI!</span>
						</div>

						<span className="text-lg text-white mt-6 mx-4">Inserisci i tuoi dati nel modulo qui sotto e verrai contattato telefonicamente dal nostro team per confermare l'ordine. Potrai chiedere tutte le informazioni che vorrai. Compila ora per ricevere {config.product_name.toUpperCase()} in offerta! 👇</span>
						<ContactForm />
					</div>

					<div className="flex flex-col px-10 py-10">
						<div className="text-center self-center text-3xl font-bold">Domande frequenti:</div>

						<div className="mt-10 flex flex-col">
							<div className="flex flex-row items-center">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon-size">
									<path fill-rule="evenodd"
										d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
										clip-rule="evenodd" />
								</svg>
								<span className="text-xl font-bold ml-4">Cosa comprende l'offera?</span>
							</div>
							<span className="text-lg mt-4">L'offerta è valida solo su questo sito e comprende una bottiglia di {config.product_name} scontata del 50%.</span>
						</div>

						<div className="mt-10 flex flex-col">
							<div className="flex flex-row items-center">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon-size">
									<path fill-rule="evenodd"
										d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
										clip-rule="evenodd" />
								</svg>
								<span className="text-xl font-bold ml-4">Posso pagare direttamente alla consegna del
									prodotto?</span>
							</div>
							<span className="text-lg mt-4">Sì, questa offerta comprende il pagamento del prodotto
								direttamente al momento della consegna a casa tua.</span>
						</div>

						<div className="mt-10 flex flex-col">
							<div className="flex flex-row items-center">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="icon-size">
									<path fill-rule="evenodd"
										d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
										clip-rule="evenodd" />
								</svg>
								<span className="text-xl font-bold ml-4">Quando arriverà il prodotto?</span>
							</div>
							<span className="text-lg mt-4">Il prodotto arriverà a casa tua entro 3 giorni lavorativi
								dall'ordine.</span>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</div >
	);
}

export default Landing;
