export default {
	product_name: "FeroCharm®",
	offer_expiration_day: "28/09/2023",
	headline: "Incrementa subito la tua autostima e il tuo potere di attrazione!",
	subheadline: (
		<div className="mt-6 px-6 text-lg lg:text-2xl">
			Oggi <span className="font-bold">FeroCharm®</span>, grazie alla sua elevata concentrazione di <span className="font-bold">feromoni</span>, rivoluzionerà la tua vita amorosa. Ti sarà <span className="font-bold">impossibile passare inosservato</span>.
		</div>
	),
	bullet_points: [
		(
			<div className="ml-3 flex flex-col items-start">
				<span className="bullet-point-text">Spray di seduzione</span>
				<span className="">Ad alta concentrazione di feromoni</span>
			</div>
		),
		(
			<div className="ml-3 flex flex-col items-start">
				<span className="bullet-point-text">Funziona</span>
				<span className="">Dalla prima volta che si usa</span>
			</div>
		),
		(
			<div className="ml-3 flex flex-col items-start">
				<span className="bullet-point-text">Sicuro</span>
				<span className="">Perché testato in laboratorio seguendo tutte le normative europee</span>
			</div>
		),
		(
			<div className="ml-3 flex flex-col items-start">
				<span className="bullet-point-text">Made in Italy</span>
				<span className="">Il top della qualità e della sicurezza</span>
			</div>
		),
	],
	full_price: "99,99",
	discounted_price: "49,99",
	usages: [
		{
			image: <img src="../assets/usage-1.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px] object-cover" />,
			text: <span className="usage-text">Attrarre grazie al tuo profumo</span>
		},
		{
			image: <img src="../assets/usage-2.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px] object-cover" />,
			text: <span className="usage-text">Aumentare la tua sicurezza</span>
		},
		{
			image: <img src="../assets/usage-3.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" />,
			text: <span className="usage-text">Essere sempre al top</span>
		}
	],
	/* instructions: [
		{
			image: <img src="../assets/instructions-1.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" />,
			text: <span className="usage-text">Istruzione 1</span>
		},
		{
			image: <img src="../assets/instructions-2.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" />,
			text: <span className="usage-text">Istruzione 2</span>
		},
		{
			image: <img src="../assets/instructions-3.jpg" className="h-[300px] w-[300px] lg:w-[500px] lg:h-[500px]" />,
			text: <span className="usage-text">Istruzione 3</span>
		}
	], */
	reviews: [
		{
			avatar: "../assets/avatar-1.jpg",
			name: "Marco Russo",
			stars: 5,
			title: "Semplicemente Efficace",
			text: "Ferocharm è discreto ma efficace. L'ho provato per curiosità e sono rimasto colpito dall'incremento della mia sicurezza personale in situazioni sociali. Ha reso le interazioni più piacevoli e ho notato un aumento dell'interesse da parte delle persone."
		},
		{
			avatar: "../assets/avatar-2.jpg",
			name: "Marco Nwankwo",
			stars: 4,
			title: "Un Aiuto per la Seduzione",
			text: "Ferocharm è diventato un mio compagno costante. La fragranza è intrigante, e ho notato che le persone rispondono positivamente quando lo indosso. È come un piccolo aiuto nella ricerca dell'attrazione."
		},
		{
			avatar: "../assets/avatar-3.jpg",
			name: "Matteo Martini",
			stars: 5,
			title: "Profumo Raffinato con un Tocco Speciale",
			text: "Ferocharm è un tocco speciale nella mia routine di cura personale. La fragranza raffinata si adatta bene a qualsiasi occasione. Mi ha aiutato a creare legami più profondi con gli altri, specialmente nelle situazioni romantiche."
		}
	],
	store_name: "Tutto Seduzione",
	privacy_url: "https://iframe.affiliatenetwork.link/landing/privacy.html",
	cta_label: "Ordina ora",
	endpoint_url: "https://network.worldfilia.net/manager/inventory/buy/ntm_ferocharm.json?api_key=6a9c42be58b9b606237e83ef67b8b9dc",
	source_id: "30f7294e5c23",
	purchase_value: "21.00",
	offer_quantity: 1
}