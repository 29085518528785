import axios from "axios"

type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE"

export const http = (url: string, method: HTTPMethod, body?: any) => {
	return axios.request({
		headers: {
			"Content-Type": "application/x-www-form-urlencoded"
		},
		url,
		method,
		data: body
	})
}