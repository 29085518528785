import React from 'react'
import config from '../utils/config'

const Guarantee = ({ textColorClass }: { textColorClass?: string }) => {
    return (
        <div className='text-center mt-6 -mx-8 items-center flex flex-col'>
            <span className={`${textColorClass ?? "text-green-600"} text-xl font-bold  mb-2`}>Prodotto garantito, soddisfatto o rimborsato!</span>
            <span>Se {config.product_name} non dovesse soddisfare le tue aspettative, potrai restituirlo entro 14 giorni e ricevere un rimborso del 100%.</span>
            <img className='mt-8 h-36 w-36' src='../assets/guarantee.png' />
        </div>
    )
}

export default Guarantee