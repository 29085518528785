import React from 'react';
import Footer from '../components/Footer';
import config from '../utils/config';

function Thankyou() {
	return (
		<div className="flex flex-col items-center min-h-[100vh]">
			<div className="flex items-center flex-col py-2 px-20 bg-slate-700 self-stretch">
				<div className="flex flex-1">
					<img className="h-12 w-52" src="../assets/logo.png" />{/* <!-- TODO: --> */}
				</div>
			</div>
			<div className='flex flex-1 flex-col items-center p-6 text-center'>
				<span className='text-3xl font-bold'>Congratulazioni per il tuo acquisto!</span>
				<span className='text-xl mt-6 font-bold'>Grazie per averci scelto!</span>
				<div className='mt-6'>
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-20 h-20 text-green-600">
						<path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
					</svg>
				</div>

				<span className='mt-6'><span className='text-red-600 font-bold'>Attenzione: </span>Verrai contattato telefonicamente nelle prossime ore dal nostro team in modo tale da confermare l'ordine.</span>
				<span className='mt-4'>Successivamente riceverai <span className='font-bold'>{config.product_name}</span> direttamente a casa tua senza nessun costo di spedizione e soprattutto pagando alla consegna. Nessun anticipo!</span>
				<div className='flex flex-col items-center mt-8'>
					<span className='font-bold text-xl'>Ecco come cambierà la tua vita</span>
					<div className='mt-4'>
						<div className='flex flex-row items-center'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 flex-shrink-0">
								<path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
							</svg>
							<span className='text-start ml-4'>D'ora in poi saranno le donne a voltarsi quando passi, catturate dalla tua fragranza.</span>
						</div>
						<div className='flex flex-row items-center mt-4'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 flex-shrink-0">
								<path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
							</svg>
							<span className='text-start ml-4'>{config.product_name} ti permetterà di avere molta più fiducia in te stesso.</span>
						</div>
						<div className='flex flex-row items-center mt-4'>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 flex-shrink-0">
								<path stroke-linecap="round" stroke-linejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
							</svg>
							<span className='text-start ml-4'>Ti sentirai sempre al top, grazie alla fragranza magnetica di {config.product_name}.</span>
						</div>
					</div>
					<span className='mt-12'>Grazie ancora per il tuo acquisto. Siamo certi che <span className='font-bold'>{config.product_name}</span> ti permettarà di vivere pienamente la tua vita.</span>
				</div>

			</div>
			<Footer />
		</div >
	);
}

export default Thankyou;
