import React from 'react'
import config from '../utils/config'

const Footer = () => {
	return (
		<div>
			<div
				className="flex flex-col self-stretch items-center py-10 px-10 text-center text-sm bg-slate-200 space-y-5">
				<span>© {config.store_name} - 2023 - <span className="font-bold">{config.product_name}.</span> Tutti i
					diritti riservati</span>
				<span>This website is not part of the Facebook website or Facebook Inc. Additionally, this site is not
					endorsed by Facebook in any way. Facebook is a trademark of Facebook, Inc.</span>
			</div>
			{/* <div className='flex flex-col self-stretch items-center p-6 text-center text-xs'>
				ATTENZIONE: i prodotti  consigliati su questo sito sono venduti in affiliazione, pertanto non siamo né il produttore, né il venditore ufficiale di ogni singolo prodotto che potrete trovare all'interno del nostro sito. Pertanto per eventuali richieste di rimborsi o altri problemi su prodotti ordinati tramite il sito, dovrete sempre fare riferimento al venditore ufficiale.Noi saremo comunque disponibili per aiutarvi e darvi supporto.
				I prodotti sono recensiti a scopo informativo: non è possibile in nessun modo garantire risultati certi, le possibilità di riuscita di qualsiasi risultato variano da cliente a cliente. 
				Alcune immagini e recensioni presenti sul blog sono state trovate sul web, qualora crediate che possano ledere i vostri diritti, comunicatecelo e tempestivamente verranno tolte.
			</div> */}
		</div>

	)
}

export default Footer

