import React from 'react';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import Landing from './routes/Landing';
import Thankyou from './routes/Thankyou';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyDdK9iFqE11BM0kXy6eoza-5cXzF9sqfbM",
	authDomain: "defrosty-b61d6.firebaseapp.com",
	projectId: "defrosty-b61d6",
	storageBucket: "defrosty-b61d6.appspot.com",
	messagingSenderId: "88979992607",
	appId: "1:88979992607:web:f7c2b32ff5f252b3aee52a",
	measurementId: "G-C7LEB3SDLG"
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <Landing />,
	},
	{
		path: "thankyou",
		element: <Thankyou />
	}
]);

function App() {
	const app = initializeApp(firebaseConfig);
	const analytics = getAnalytics(app);
	return (
		<div className="flex flex-col h-full">
			<RouterProvider router={router} />
		</div >
	);
}

export default App;
